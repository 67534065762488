/* desktop app bar */

/* 
    Big old FIXME: Putting this here for now, as the header is being used in other apps and we need to have a larger
    conversation around what should be shared design. Also this needs to be in a more MUI-specific way, or at least
    have id's added to each header section so that scraping these classes is easier and less brittle.
 */

/* this is the upper menu section (ef logo section) */
nav.MuiPaper-root.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic {
  & div.MuiContainer-root.MuiContainer-maxWidthLg {
    padding: 0 0;
  }

  /* mobile hamburger button/icon */
  & button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-sizeMedium.MuiButton-root.MuiButton-text.MuiButton-sizeMedium {
    min-width: fit-content;
    padding-left: 4px;
    padding-right: 4px;
  }

  & a.MuiButton-root.MuiButton-appnav,
  button.MuiButton-root.MuiButton-appnav {
    border: 0;

    /* "active" marker, little blue bar */
    &::before {
      content: "";
      position: absolute;
      top: 100% - 3px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: transparent;
      border-radius: 3px 3px 0 0;
      transition-property: background-color;
      transition: linear 0.2s;
      transition-delay: 0.2s;
    }

    &.active::before {
      background-color: #0068E0;
      /* uiDigitalFirstBlue in theme */
    }
  }
}

/* mobile overlay */
div.MuiDialog-root.MuiModal-root div.MuiDialog-container.MuiDialog-scrollPaper {

  /* close/'X' button on mobile nav */
  & nav.MuiAppBar-root div.MuiStack-root a+button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    min-width: fit-content;
  }

  /* icons at right of each menu item */
  & svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    font-size: 2rem;
  }

  /* 'primary' nav links in the top section */
  & nav.MuiList-root li.MuiListItem-root {

    & a div.MuiListItemText-root strong.MuiTypography-root {
      font-weight: bold;
    }
  }

  & nav.MuiList-root a.MuiButtonBase-root div.MuiListItemText-root {
    font-weight: bold;
  }

  /* user section links ('profile'; 'my rewards') */
  & .MuiListItem-gutters.MuiListItem-padding a div.MuiListItemText-root p {
    font-weight: normal;
  }

  /* bottom section links ('refer a friend'; 'logout') */
  & ul.MuiList-root {

    /* continue gray background to the end of the screen, if screen is taller than menu */
    height: 100%;

    /* hide icons for menu items, but leave it for 'logout' */
    & li.MuiListItem-root:not(:last-child) svg.MuiSvgIcon-root {
      visibility: hidden;
    }
  }
}